.overley-wrapper {
    width: 0;
    height: 300%;
    overflow: hidden;
    z-index: 100;
    position: fixed;
    top: 0;
    padding: 20px 0;
    background: rgba(0, 0, 0, 0.5);

    

    .title-wrapper {
        background-color: $primary_color;
        position: relative;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px 8px 0px 0px;

        img {
            position: absolute;
            right: 0;
            top: 0;
            margin-right: 20px;
            margin-top: 10px;
            cursor: pointer;
        }

        h1 {
            color: $text_color;
            font-family: 'FiraGO Regular';
            font-weight: 600;
            font-size: 32px;
        }
    }
}

.text-wrapper-overley {
    padding: 0 54px;

    p {
        margin-top: 86px;
        font-family: 'FiraGO Regular';
        font-weight: 500;
        font-size: 14px;
    }
}

.img-wrapper-overley {
    margin-top: 28px;
    padding: 0 54px;
}
.imgs {
    margin-top: 86px;
    padding: 0 54px;
}

.info-wrapper {
    background-color: $text_color;
    overflow: auto;
    height: calc(100% - 80px);
    border-radius: 0 0 8px 8px;
}

.active-overley {
    width: 100%;
}


.content-wrapper {
    opacity: 0;
    transition: 0.4s ease;

    &-content-active {
        opacity: 1;
        transition: 0.4s ease;
        height: 90vh;
        overflow: hidden;
    }
}

.primary-color-wrapper {
    height: 80px;
}


