.switcher-sliders {  
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    
    div:nth-child(2) {
        margin-top: 28px;
    }
}

.imgdouble {
    width: 159px;
    height: 159px;
    margin: 14px;
    border: 1px solid #e9e9e9;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
    &:hover{
        border: 1px solid $primary_color;
        transition: 0.3s;
    }
    img {
        width: 150px !important;
    }
}


.switcher-slider-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}


.partners {
    width: 150px;
}


.swiperselector {
    padding: 24px 0;

    button {
        width: 200px;
        font-size: 16px;
        background-color: #c1c1c1;

        &:hover{
            scale: 1;
            background-color: $primary_color;
        }
    }

    .active-button {
        background-color:$primary_color;
    }

    // .btn-changer {
    //     margin-bottom: 34px;
    // }

    .swiper-button-prev:after, .swiper-button-next:after {
        color: $primary_color;
        position: absolute !important;
    }

    .swiper-button-prev:after {
        margin-right: 23px;
    }
    .swiper-button-next:after {
        margin-left: 23px;
    }
}

.hide {
    position: absolute;
    width: 0;
    height: 0;
    
}

.unhide {
    width: 100%;
    height: auto;
    // transition: 0.4s;
}





.opacityzero { 
    opacity: 0;
}

.opacityone { 
    opacity: 1;
    transition: 1s;
}




@media screen and (max-width: 992px) {
    .jc-center-media-992 {
        justify-content: center;
    }
  }