.sliderimgs {
    display: flex;
    flex-direction: column;
    
    div {
    width: 189px;
    height: 189px;
    border: 1px solid #e9e9e9;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    &:hover{
        border: 1px solid $primary_color;
        transition: 0.3s;
    }
    img {
        width: 120px !important;
    }
    }

    div:last-child {
        margin-top: 30px;
    }
  
   
  }
  
  @media screen and (max-width: 480px){
    .sliderimgs {
      display: flex;
      flex-direction: column;
      
      div {
      width: 189px;
      height: 189px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s;
      &:hover{
          border: none;
          transition: 0.3s;
      }
      img {
          width: 120px !important;
      }
      }
  
      div:last-child {
          margin-top: 30px;
      }
    
     
    }
  }
  
  
  
  .swiper-arrow { 
  
  
    .swiper-button-next:after {
      color:$primary_color;
      margin-left: 21px;
    }
    .swiper-button-prev:after {
      color:$primary_color;
      margin-left: -21px;
    }
  
  
    // .swiper-pagination-bullet {
    //   background-color: $primary_color !important;
    //   margin-top: 100px !important;
    // }
  }
  

  // @media screen and (max-width: 480px) {
  //   .sliderimgs {
  //     // padding: 2rem;
  //     border-radius: 1px solid black;
  //     div {
  //       border: none !important;
  //     }
  //   }
  // }