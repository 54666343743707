.about-desc {
  p {
    font-family: "FiraGO Regular";
    font-weight: 500;
    font-size: 14px;
  }
}

.on-photo {
    margin-top: -80px !important;
    z-index: 10;
    background: white;
    padding: 30px;
    // border-bottom: 1px solid;
    border-radius: 8px 8px 0 0;
}

.fluidcolor {
  background-image: url(../../pictures/about.jpg);
  background-color: #cccccc;
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 16px 0;
  position: relative;
  // margin-top: 56px;

  h1 {
    color: $body_color;
    font-size: 46px;
    z-index: 5;
  }
}

.shadow-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.24);
}

.cert {
    width: 600px;
}
