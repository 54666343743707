.news-paper {
  width: 100%;
}

.news-img {
  width: 100%;
  height: 250px;
//   background-image: url(../../pictures/newspic.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}
.new-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
}

.news-box {
  padding-bottom: 12px;
  
  h2 {
    margin-top: 13px;
    padding: 0 13px;
    margin-bottom: 14px;
    font-size: 18px;
    text-align: center;
    font-family: FiraGo Regular;
    font-weight: 600;
  }

  

  p {
    padding: 0 13px;
    font-family: FiraGo Regular;
    font-size: 12px;
    font-weight: 500;
  }

  // button {
  //   margin-top: 12px;
  //   margin-left: 280px;
  // }
  
  .news-button-container {
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
  }

  // @media screen and (max-width: 740px) {
  //   button {
  //     margin-left: 332px;
  //   }
  // }
}


.news-container {
  padding: 1rem;
}


.modal-title {
  font-size: 26px;
}


.services-info {
  img {
    width: 200px;
  }
}
