main {
  width: 100%;
}

h1 {
  font-family:'FiraGo Regular' !important;
  font-weight: 600;
  font-size: 36px;
}

@media screen and (max-width: 740px) {
  h1 {
    font-size: 24px;
  }
}

.pic {
  width: 100%;
}

.about-wrapper {
  width: 365px;
  padding: 14px 0;

  h2 {
    font-family: "FiraGo Regular";
    font-weight: 600;
    font-size: 24px;
  }

  p {
    font-family: "FiraGo Regular";
    font-size: 14px;
    font-weight: 500;
  }
}

.container-color {
  background-color: $footer_color;
}

.box {
  padding: 62px 40px;
  width: 464px;
  height: 196px;
  background-color: $primary_color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: FiraGo Regular;
  font-weight: 600;
  color: $text_color;
  cursor: pointer;
  border-radius: 8px;

  h2 {
    font-size: 26px;
    font-weight: 600;
    padding: 16px 0;
    transition: 0.2s ease;
  }

  p {
    font-size: 18px;
  }

  img {
    position: absolute;
    transition: 0.2s ease;
  }

  &:hover {
    img {
      scale: 0.5;
      margin-left: -130px;
    }

    h2 {
      font-size: 16px;
    }

    p {
      margin-top: -40px;
      // margin-left: 30px;
    }
  }
}

.border {
  border: 1px solid $border_color;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 19%);
  border-radius: 8px;
}

.border-bottom {
  border-bottom: 1px dashed #c1c1c1;
}

.map {
  width: 473px;
  height: 256px;

  iframe {
    width: 100%;
    height: 100%;
    border-radius: 8px 0 0 8px;
    border: none;
  }
}

.contact-wrapper {
  z-index: 10;
  width: 677px;
  height: 256px;
  background-color: $primary_color;
  border-radius: 8px;
  padding: 24px 45px;
  h2 {
    font-family: FiraGo Regular;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: $text_color;
  }
  p {
    font-family: FiraGo Regular;
    font-weight: 500;
    color: $text_color;
    font-size: 14px;
  }
}

.text-wrapper {
  img {
    margin-right: 33px;
  }
  p {
    display: flex;
    align-items: center;
  }
  p:nth-child(2) {
    padding: 17px 0;
  }
}

.footer {
  height: 300px;
  margin-top: -150px;
  z-index: -1;
  background-color: $footer_color;
  p {
    font-family: FiraGo Regular;
    font-weight: 400;
    font-size: 14px;
    color: $text_color;
    margin-top: 260px;
  }
}

@media screen and (max-width: 750px) {
  .footer {
    height: auto;
  }
}



.service-wrapper {
  a {
    text-decoration: none;
  }
}

.spier-height {
  height: 500px;
  border-radius: 8px;

  

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    
  }


  h1 {
    font-size: 2.5rem !important;
    font-weight: 600 !important;
    font-family: "FiraGo Regular" !important;
    color: $text_color;
  }

  p{
    font-size: 1.5rem !important;
    font-family: "FiraGo Regular" !important;
    font-weight: 300 !important;
    color: $text_color;
    margin-bottom: 10px;
  }
  
}

.img-wrapper-about {
  width: 365px;
  height: 350px;
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}

.main-title {
  padding-bottom: 20px;
  h1 {
    color: $body_color;
  }
}

.title-font-size {
  font-size: 26px !important;
  font-weight: 600 !important;
}
@media screen and (max-width: 750px) {
  .title-font-size {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  .text-size {
    font-size: 14px !important;
  }
}

.active-swiper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  transition: all 0.2s ease;
  
  .swiper-pagination-bullet {
    background-color: $footer_color !important;
  }
  .swiper-pagination {
    height: 5px;
  }

  img{
    width: 150px;
    height: auto;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-unactive {
    transition: all 0.2s ease;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    overflow: hidden;
  }
}


.swiper-size{
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;

  .jc-al-center{
    height: 100%;
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.overflow-hidden {
  overflow: hidden;
}


.overflow-x-hidden-pages {
  overflow-x: hidden;
}

.padding-left-tworem {
  // align-items: center;
  padding:0 4rem;
}

.flex-direction-columnn {
  flex-direction: column;
}


.hyperlink {
  a {
    color: $primary_color;
  }
}


.padding-02 {
  padding-bottom: 0.2rem;
}


@media screen and (max-width: 500px) {
  .box {
    
    h2 {
      font-size: 20px !important;
    }
  }
}
@media screen and (max-width: 414px) {
  .box {
    width: 390px;
  }
}
@media screen and (max-width: 412px) {
  .box {
    width: 385px;
  }
}
@media screen and (max-width: 393px) {
  .box {
    width: 366px;
  }
}
@media screen and (max-width: 390px) {
  .box {
    width: 365px;
  }
}
@media screen and (max-width: 375px) {
  .box {
    width: 350px;
  }
}
@media screen and (max-width: 360px) {
  .box {
    width: 334px;
  }
}
@media screen and (max-width: 280px) {
  .box {
    width: 255px;
  }
}



@media screen and (max-width:500px){
  .spier-height {
    h1 {
      font-size: 1.5rem !important;
    }
  
    p{
      font-size: 1rem !important;
    }
  }
}


