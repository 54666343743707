.primary-background {
    background-color: $primary_color;
}

.services-title {
    padding: 21px 0;

    p {
        color: $text_color;
        font-family: FiraGo Regular;
        font-weight: 500;
        font-size: 16px;
    }
}

.services-info {
    padding: 28px 24px;
    margin-top: 28px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 19%);
    border-radius: 8px;

    h1 {
        padding-bottom: 20px;
        font-family: FiraGo Regular;
        font-weight: 800;
        font-size: 14px;
    }
    p{
        font-family: FiraGo Regular;
        font-weight: 500;
        font-size: 14px;
    }
}


.padding-top {
    padding-top: 30px;
    padding-bottom: 12px;
}