$primary_color:#c92e04;
$text_color:#ffffff;
$body_color:#ffffff;
$footer_color:#333333;
$navbar_color:#000000;
$border_color:#BDBDBD;
$swift_color:#4d90fe;
$kyc_color:#35aa47;


// DA3100