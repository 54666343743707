.filtered {
    ul {
        li {
            list-style: none;
            padding: 22px;

            a {
                position: relative;
                text-decoration: none;
                font-family: FiraGo Regular;
                font-weight: 600;
                font-size: 14px;
            }

            a:nth-child(1) {
                color: $navbar_color;
                padding-right: 18px;
            }

            a:nth-child(2) {
                color: $swift_color;
            }

            a:nth-child(3) {
                padding: 0 18px;
                color: $kyc_color;
            }

            a:nth-child(4) {
                padding-right: 18px;
                color: $primary_color;
            }
            a:nth-child(5) {
                color: $navbar_color;
            }


            a:after {
                content: "";
                position: absolute;
                background-color: $primary_color;
                height: 3px;
                width: 0;
                left: 0;
                bottom: -5px;
                transition: 0.2s;
            }

            a:hover:after {
                width: 100%;
            }
        }
    }

}