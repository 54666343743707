nav {
  align-items: center;
  display: flex;
  transition: all 0.4s ease;
  padding: 5px 0;
  border: 1px solid $body_color;

  .logo {
    transition: all 0.4s ease;
    height: 150px;
  }

  ul {
    display: flex;
    align-items: center;

    li {
      list-style: none;
      padding-right: 12px;
      font-family: FiraGo Regular;
      font-weight: 600;
      font-size: 14px;

      a {
        cursor: pointer;
        position: relative;
        padding: 0 2px;
        color: $navbar_color;
        text-decoration: none;
      }

      a:after {
        content: "";
        position: absolute;
        background-color: $primary_color;
        height: 3px;
        width: 0;
        left: 0;
        bottom: -5px;
        transition: 0.2s;
      }

      a:hover:after {
        width: 100%;
      }    
    }
  }

  .active-tab{
    .active{
      cursor: pointer;
      position: relative;
      padding: 0 2px;
      color: $navbar_color;
      text-decoration: none;
    }  

  .active:after {
      content: "";
    position: absolute;
    background-color: $primary_color;
    height: 3px;
    width: 100%;
    left: 0;
    bottom: -5px;
    transition: 0.2s;
    }
  }


  div {
    align-items: center;

    button {
      margin-left: 24px;
    }

    .uk {
      margin-left: 24px;
      cursor: pointer;
    }
  }
}

.dropdown-menu {
  display: none !important;
}

@media screen and (max-width: 992px) {
  .navbar-media {
    position: absolute;
    width: 0px;
    height: 0px;
    overflow: hidden;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start !important;
    background-color: $text_color;
    z-index: 100;
    // box-shadow: 0 2px 6px 0 rgb(0 0 0 / 6%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 49%), 0 32px 45px 0 rgb(0 0 0 / 50%);
    transition: 0.2s ease;

    div {
      button {
        margin: 0;
      }
      img {
        margin: 0;
      }
    }

    .button-wrapper {
      margin-top: 20px;
    }
    .uk {
      margin-top: 40px;
    }

    ul {
      flex-direction: column;
      align-items: flex-start;
      li {
        padding: 20px 0;
        a {
          padding: 0;
        }
      }
    }
  }

  .dropdown-active {
    width: 280px;
    position: fixed;
    overflow: hidden !important;
    height: 100%;
    transition: 0.2s ease;
  }

  .dropdown {
    margin-left: 80px;
  }

  .dropdown-menu {
    display: flex !important;
    right: 13%;
    // margin-right: 100px;
  }
}

.menuboard {
  cursor: pointer;
}

.navbar-fixed {
  position: sticky;
  background-color: $body_color;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 6%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  z-index: 100;
  top: 0px;
  transition: 0.4s ease;

  .logo {
    transition: all 0.4s ease;
    height: 70px;
  }
}

.dropdown-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0));
  border: none;
  border-radius: 0;
  background-color: transparent;
  color: $navbar_color;
  cursor: pointer;
  font-family: FiraGo Regular;
  font-weight: 600;
  font-size: 14px;
  transition: 0s ease;
  margin-left: 0;

  img {
    padding-right: 0px;
  }

  &:hover {
    scale: 1;
  }
}

.dropdown {
  height: 0px;
  overflow: hidden;
  position: absolute;
  padding: 0px 0px;
  margin-top: 0px;
  transition: 0.2s ease;
  z-index: 10;

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    background-color: $body_color;
    
    // border: 1px solid $body_color;
    padding: 0 12px;
    // border: 1px solid $border_color;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 6%), 0 2px 10px 0 rgb(0 0 0 / 5%);

    img {
      width: 40px;
      height: 40px;
      margin-left: 10px;
    }

    li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;

      a {
        color: $navbar_color;
        text-decoration: none;
      }
    }

    // li:nth-child(2) {
    //     padding: 10px 0;
    //     padding-right: 12px;
    // }
  }
}

.activee {
  height: auto;
  padding: 10px;
}

.arrow-open {
  transform: rotate(180deg);
  transition: 0.2s ease;
  width: 16px;

  &-close {
    transform: rotate(0deg);
    transition: 0.2s ease;
    width: 16px;
  }
}

.casemanager {
  button {
    background-color: $primary_color;
    a {
      text-decoration: none;
      color: $text_color;
    }
  }
}


.bounce {
    &-active {
        animation: bounce 1s linear;
    }
}

@keyframes bounce {
  20%,
  50%,
  80%,
  to {
    scale: 1;
  }
  30% {
    scale: 1.3;
  }
  40% {
    scale: 1.3;
  }
  70% {
    scale: 1.2;
  }
  90% {
   scale: 1.1;
  }
}
