* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 12px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: none;
  border-radius: 8px;
  background-color: $primary_color;
  color: $text_color;
  cursor: pointer;
  font-family: FiraGo Regular;
  font-size: 14px;
  transition: 0.2s ease;

  img {
    padding-right: 8px;
  }

  &:hover {
    scale: 1.1;
  }
}

.d-flex {
  display: flex;
}

.jc-end {
  justify-content: end;
}

.jc-center {
  justify-content: center;
}

.txt-center {
  text-align: center;
}

.p-tb-20 {
  padding: 20px 0;
}

.plr-13 {
  padding: 0 13px;
}

.al-center {
  align-items: center;
}
.al-start {
  align-items: flex-start;
}

.mt-80 {
  margin-top: 80px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-34 {
  margin-top: 34px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-10 {
  margin-top: 10px;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.p-0 {
  padding: 0;
}

.mt-60-minus {
  margin-top: -60px;
}

.pb-100 {
  padding-bottom: 100px;
}

.p-12 {
  padding: 12px;
}

.pb-40 {
  padding-bottom: 40px;
}

.p-tb {
  padding: 40px 0 62px 0;
}

.ptb-16 {
  padding: 16px 0;
}

@media screen and (max-width: 992px) {
  .media-p-tb {
    padding: 40px 0 40px 0;
  }
  .media-jc-center {
    justify-content: center;
  }
  .media-mt-40 {
    margin-top: 40px;
  }
  .mt-34-media-992px {
    margin-top: 34px;
  }
}

.mt-40 {
  margin-top: 40px;
}



.news {
  a {
    color: $text_color;
    text-decoration: none;
  }
}

.modal-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  background-color: $primary_color;
  color: $text_color;
  font-family: "FiraGO Regular";
  font-weight: 600;
  font-size: 32px;

  button {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 20px !important;
    margin-top: 10px !important;
    cursor: pointer;
  }
}

.btn-close:hover {
  color: red;
}

.modal-description {
  padding: 32px 54px;

  p {
    font-family: "FiraGO Regular";
    font-weight: 500;
    font-size: 16px;
  }
}

.swiper-container {
  width: 480px;
}

.form-font {
 
  ::placeholder {
    font-family: "FiraGO Regular";
    font-weight: 500;
    font-size: 16px;
  }

  label {
    font-family: "FiraGO Regular";
    // font-size: 16px;
    font-weight: 600;
    padding: 18px 0;
  }

  option {
    font-family: "FiraGO Regular" !important;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
  }
  select {
    font-family: "FiraGO Regular";
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
  }
}

@media screen and (max-width:1200px){
.flex-direction-column {
  flex-direction: column;
}
}


// .underline {
//   border-bottom: 1px solid $border_color;
// }

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}


.swiper-button-next:after,
.swiper-button-prev:after
 {
  color: $body_color;
}
.swiper-pagination-bullet{
  background-color: white !important;
}

.shadow-for-container {
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 6%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  border-radius: 8px;
}





// @media screen and (max-width:1400px){
//   .flex-direction-column-1400px {
//     flex-direction: column;
  
//     .mt-12-media-1400px{
//       margin-top: 40px;
//       display: flex;
//       justify-content: center;
      
//     }
//   }
//   }

//   @media screen and (max-width:1400px) {
//     .width-500px {
//       width:440px;
//     }
//   }

//   @media screen and (min-width:500px) {
//     .width-500px {
//       width:auto !important;
//     }
//   }

.media-jc-center {
  a {
    text-decoration: none;
  }
}

.m-0 {
  margin: 0 !important;
}

.mt-20 {
  margin-top: 14px;
}


