.switcher {
  position: relative;
  margin-left: 20px;

  img {
    position: absolute;
    transition: 0.2s ease;
    cursor: pointer;
    margin-top: -14px;
  }

  &:hover {
    img:nth-child(2) {
      padding: 2rem 0;
    }
    img:nth-child(3) {
      padding: 4rem 0;
    }
  }
}

.switcher:nth-child(2) {
  padding: 2rem 2rem;
}


@media screen and (max-width: 992px) {
  .switcher {
    img {
      margin-top: 16px !important;
      margin-left: -18px !important;
    }
  }
}
