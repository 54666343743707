html {
  scroll-behavior: smooth;
}

body {
    background-color: $body_color;
}

::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 5px 5px 5px $text_color; 
    border-radius: 8px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $footer_color; 
    border-radius: 8px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $footer_color; 
  }

  